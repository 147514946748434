import React from "react"
import styled from "styled-components"
import { Parallax } from "react-parallax"

const ParallaxText = styled.div`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  width: 80%;

  @media (min-width: 768px) {
    width: 60%;

    h1 {
      font-size: 54px;
      font-weight: normal;
    }

    p {
      font-size: 22px;
    }
  }
`

const ParallaxContainer = styled.div`
  height: ${props => (props.homePage ? "90vh" : "60vh")};
  @media (min-width: 768px) {
    height: ${props => (props.height ? props.height : "60vh")};
  }
`

const StyledParallax = styled(Parallax)`
  img {
    object-fit: cover;
    @media (min-width: 768px) {
      transform: translate3d(
        ${props => (props.translate ? props.translate : "-50%, 0%, 0px")}
      ) !important;
    }
  }
`

export default function ParallaxBackground({
  bgImage,
  bgImageAlt,
  strength,
  height,
  homePage,
  translate,
  children,
}) {
  return (
    <StyledParallax
      bgImage={bgImage}
      bgImageAlt={bgImageAlt}
      strength={strength ? strength : "500"}
      homePage={homePage}
      translate={translate}
    >
      {children && (
        <ParallaxContainer
          height={height}
          style={{
            backgroundImage:
              "linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))",
          }}
        >
          <ParallaxText data-sal="fade" data-sal-easing="ease">
            {children}
          </ParallaxText>
        </ParallaxContainer>
      )}
    </StyledParallax>
  )
}
