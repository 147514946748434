import React from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { ContentContainer } from "../components/Theme"
import { rhythm } from "../utils/typography"
import ParallaxBackground from "../components/ParallaxBackground"

const Post = styled.div`
  display: flex;
  margin: 15px 0;
`

const PostImage = styled.div`
  flex: 25;
  margin-right: 1rem;
`

const PostText = styled.div`
  flex: 75%;
`
export default function blog({ data }) {
  const posts = data.allContentfulPost.edges
  const title = data.allContentfulBlogPage.edges[0].node.title
  const heroImage = data.allContentfulBlogPage.edges[0].node.heroImage.file.url

  return (
    <Layout>
      <SEO title="Blog" />
      <ParallaxBackground
        bgImage={heroImage}
        bgImageAlt="Blog Page"
        height="50vh"
      >
        <h1 data-sal="fade" data-sal-easing="ease">
          {title}
        </h1>
      </ParallaxBackground>
      <ContentContainer>
        {posts.map(({ node }) => {
          const title = node.title || node.slug
          return (
            <Post key={node.slug} data-sal="fade" data-sal-easing="ease">
              {node.image && (
                <PostImage onContextMenu={e => e.preventDefault()}>
                  <Link style={{ boxShadow: `none` }} to={`/${node.slug}`}>
                    <Img fluid={node.image && node.image.fluid} />
                  </Link>
                </PostImage>
              )}
              <PostText>
                <h3
                  style={{
                    marginTop: 0,
                    marginBottom: rhythm(1 / 4),
                  }}
                >
                  <Link style={{ boxShadow: `none` }} to={`/${node.slug}`}>
                    {title}
                  </Link>
                </h3>
                <section>
                  <p>{node.subtitle}</p>
                </section>
              </PostText>
            </Post>
          )
        })}
      </ContentContainer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allContentfulPost(sort: { fields: [createdAt], order: DESC }) {
      edges {
        node {
          title
          subtitle
          slug
          image {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          content {
            json
          }
        }
      }
    }
    allContentfulBlogPage {
      edges {
        node {
          title
          heroImage {
            file {
              url
            }
          }
        }
      }
    }
  }
`
